@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif;
}

.blinking {
  animation: blinking 1.2s infinite ease-out;
}

@keyframes blinking {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0.02;
  }
}

/* gradient around an image */
.gradient {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

svg[fill],
svg *[fill] {
  fill-opacity: initial;
}
svg[fill="none"],
svg *[fill="none"] {
  fill-opacity: 0;
}
